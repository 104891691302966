<template>
	<div class="resultarea">
		<div class="rbox">
			<div style="text-align: center;white-space: pre-line;">{{cresult}}</div>
		</div>
		<div style="text-align: center;margin: 1rpx 3%;">
			按照不加百分号的百分比方式输入(原本：1557%→输入：1557)<br/>
			正常训练的话期末百分比填写100即可，所有项必填<br/>
			请自行计算额外百分比的总和后填入<br/>
		</div>
	</div>
	<div class="inputarea">
		<input class="textedit" type="text" placeholder="元气层数" v-model="dunbase"/>
		<input class="textedit" type="text" placeholder="干劲(やる気)层数" v-model="kibase"/>
		<input class="textedit" type="text" placeholder="卡片元气倍率" v-model="dunper"/>
		<input class="textedit" type="text" placeholder="卡片干劲(やる気)倍率" v-model="kiper"/>
		<input class="textedit" type="text" placeholder="期末百分比加成" v-model="perNum"/>
		<input class="textedit" type="text" placeholder="额外百分比加成(输入百分比总和)" v-model="experNum"/>
		<input class="submitb" type="button" value="开始计算" @click="caclresult"/>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				cresult:"结果将在此显示",
				dunbase:null,
				kibase:null,
				dunper:null,
				kiper:null,
				perNum:null,
				experNum:null,
			};
		},
		methods:{
			caclresult(){
				//元气造成的基本伤害
				var dun_damage = Number(this.dunbase) * Number(this.dunper/100);
				
				//干劲造成的基本伤害
				var ki_damage = Number(this.kibase) * Number(this.kiper/100) * (Number(this.experNum)+1);
				
				//算上额外百分比
				var perList = this.experNum.split("+");
				for(var per of perList) {
					dun_damage *= Number(per)/100 + 1;
					ki_damage *= Number(per)/100 + 1;
				}

				//取整操作
				dun_damage = Math.ceil(dun_damage);
				ki_damage = Math.ceil(ki_damage);

				// 算上期末百分比后的结果
				var dun_result = Math.ceil(dun_damage * this.perNum/100);
				var ki_result = Math.ceil(ki_damage * this.perNum/100);

				//总伤害
				var result = dun_result + ki_result;
				this.cresult = "总计可造成："+result+"\n元气可造成："+dun_result+"\n干劲(やる気)可造成："+ki_result;
			}
		}
	}
</script>

<style>
</style>
