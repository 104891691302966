<template>
	<div class="wbox">
		<div class="card">
			<img class="sanicon" src="../assets/sanwei/vo.png"/>
			<input v-model="von" class="sanedit" type="text" placeholder="基础值" style="background-color: #f4e3e5;">
			<input v-model="voper" class="sanedit" type="text" placeholder="加成值" style="background-color: #f4e3e5;">
		</div>
		<div class="card">
			<img class="sanicon" src="../assets/sanwei/da.png"/>
			<input v-model="dan" class="sanedit" type="text" placeholder="基础值" style="background-color: #d4e1f4;">
			<input v-model="daper" class="sanedit" type="text" placeholder="加成值" style="background-color: #d4e1f4;">
		</div>
		<div class="card">
			<img class="sanicon" src="../assets/sanwei/vi.png"/>
			<input v-model="vin" class="sanedit" type="text" placeholder="基础值" style="background-color: #f4f2cf;">
			<input v-model="viper" class="sanedit" type="text" placeholder="加成值" style="background-color: #f4f2cf;">
		</div>
	</div>

	<div>
		<input class="masterBtn" type="button" value="Master" :style="masterBtnStyle" @click="masterClick">
	</div>
	

	<div style="text-align: center;">
		<div class="resultarea">
			<div class="rbox" style="background-color: #f4e3e5;">
				<div style="text-align: center;white-space: pre-line;">{{vo}}</div>
			</div>
		</div>
		<div class="resultarea">
			<div class="rbox" style="background-color: #d4e1f4;">
				<div style="text-align: center;white-space: pre-line;">{{da}}</div>
			</div>
		</div>
		<div class="resultarea">
			<div class="rbox" style="background-color: #f4f2cf;">
				<div style="text-align: center;white-space: pre-line;">{{vi}}</div>
			</div>
		</div>
	</div>
	<input class="submitb" type="button" value="开始计算" @click="caclresult"/>
</template>

<script>
	export default {
		data() {
			return {
				isMaster:true,
				masterBtnStyle:"background-color:#ff8b23;",
				von:null,
				voper:null,
				dan:null,
				daper:null,
				vin:null,
				viper:null,
				vo:"选红结果将在此展示",
				da:"选蓝结果将在此展示",
				vi:"选黄结果将在此展示"
			};
		},
		methods:{
			masterClick(){
				console.log("click");
				this.isMaster = !this.isMaster;
				if(this.isMaster){
					this.masterBtnStyle = "background-color: #ff8b23;";
				}else{
					this.masterBtnStyle = "background-color: #f1f1f1;";
				}
			},
			add(basenum,pernum,addnum,max){
				var result = addnum*(1+pernum/100)+basenum;
				if(result>max){
					return max;
				}
				return Math.floor(result);
			},
			cacleonce(voa,daa,via){
				var maxValue = this.isMaster?1800:1500;
				var origin = Number(this.von)+Number(this.dan)+Number(this.vin);
				var rred = this.add(Number(this.von),Number(this.voper),voa,maxValue);
				var rblue = this.add(Number(this.dan),Number(this.daper),daa,maxValue);
				var ryellow = this.add(Number(this.vin),Number(this.viper),via,maxValue);
				var target = rred + rblue + ryellow;
				var finalscore = Math.min(rred+30,maxValue) + Math.min(rblue+30,maxValue) + Math.min(ryellow+30,maxValue);
				return [origin,target,finalscore,rred,rblue,ryellow];
			},
			caclresult(){
				//追红
				var rlist = this.cacleonce(310,145,145);
				this.vo = `追红后结果为：${rlist[0]}→${rlist[1]}\n期末后：${rlist[1]}→${rlist[2]}
				Vo:${this.von}→${rlist[3]}\nDa:${this.dan}→${rlist[4]}\nVi:${this.vin}→${rlist[5]}`;
				//追蓝
				var blist = this.cacleonce(145,310,145);
				this.da = `追蓝后结果为：${blist[0]}→${blist[1]}\n期末后：${blist[1]}→${blist[2]}
				Vo:${this.von}→${blist[3]}\nDa:${this.dan}→${blist[4]}\nVi:${this.vin}→${blist[5]}`;
				//追黄
				var ylist = this.cacleonce(145,145,310);
				this.vi = `追黄后结果为：${ylist[0]}→${ylist[1]}\n期末后：${ylist[1]}→${ylist[2]}
				Vo:${this.von}→${ylist[3]}\nDa:${this.dan}→${ylist[4]}\nVi:${this.vin}→${ylist[5]}`;
			}
		}
	}
</script>

<style>
</style>
