<template>
	<div class="resultarea">
		<div class="rbox">
			<div style="text-align: center;white-space: pre-line;">{{cresult}}</div>
		</div>
		<div style="text-align: center;margin: 1rpx 3%;">
			按照不加百分号的百分比方式输入(原本：1557%→输入：1557)<br/>
			正常训练的话期末百分比填写100即可，所有项必填<br/>
			请自行计算额外百分比的总和后填入<br/>
		</div>
	</div>
	<!--计算公式 [(基础值+集中)*(1+0.5+0.1*好调层数)]*(1+额外百分比)  -->
	<!-- 上述向上取整后*(期末百分比)，之后结果向上取整 -->
	<div class="inputarea">
		<input class="textedit" type="text" placeholder="卡片基础值(多段使用+分割)" v-model="baseNum"/>
		<input class="textedit" type="text" placeholder="每段集中倍率(多段使用+分割)" v-model="jizhongPers"/>
		<input class="textedit" type="text" placeholder="集中层数" v-model="jizhongNum"/>
		<input class="textedit" type="text" placeholder="好调层数" v-model="haodiaoNum"/>
		<input class="juehaob" :style="juebtncolor" type="button" name="btn1" v-bind:value="showtext" @click="jueButtonClick"/>
		<input class="textedit" type="text" placeholder="期末百分比加成" v-model="perNum"/>
		<input class="textedit" type="text" placeholder="额外百分比加成(输入百分比总和)" v-model="experNum"/>
		<input class="submitb" type="button" value="开始计算" @click="caclresult"/>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				has_jue:false,
				juebtncolor:"background-color: #f1f1f1;",
				showtext:"未拥有绝好调",
				cresult:"结果将在此显示",
				baseNum:null,
				jizhongPers:null,
				jizhongNum:null,
				haodiaoNum:null,
				perNum:null,
				experNum:null,
			};
		},
		methods:{
			jueButtonClick(){
				this.has_jue = !this.has_jue;
				this.showtext = this.has_jue?"拥有绝好调":"未拥有绝好调";
				this.juebtncolor = this.has_jue?"background-color: #A7B7FF;":"background-color: #f1f1f1;"
			},
			caclonce(base,jizhongPer,jizhong,haodiaoPer,experNum,qimo){
				var experList = experNum.split("+");
				var baseN1 = jizhong*jizhongPer + base;
				//第一次取整伤害
				var first = baseN1*(1+haodiaoPer);
				//每段额外均独立计算
				for(var per of experList){
					first *= Number(per)/100 + 1;
				}
				first = Math.ceil(first);
				return Math.ceil(Math.ceil(first)*(qimo/100));
			},
			caclresult(){
				//基础数值
				var base_nums = [this.baseNum];
				if(this.baseNum.includes("+")){
					base_nums = this.baseNum.split("+");
				}
				//集中倍率
				var jizhong_nums = [this.jizhongPers];
				if(this.jizhongPers.includes("+")){
					jizhong_nums = this.jizhongPers.split("+");
				}
				//好调百分比
				var haodiaoP = 0;
				if(this.haodiaoNum > 0){
					haodiaoP = this.has_jue?this.haodiaoNum*0.1+0.5:0.5;
				}
				
				var resultList = [];
				var resultTotal = 0;
				for(var i in base_nums){
					var r = this.caclonce(Number(base_nums[i]),Number(jizhong_nums[i]),Number(this.jizhongNum),haodiaoP,this.experNum,Number(this.perNum))
					resultList.push(r);
					resultTotal += r;
				}
				var text = "每段可造成："+resultList.join("/")+"\n";
				this.cresult = text + "总计可造成："+resultTotal;
			}
		}
	}
</script>

<style>

/*PC端*/
@media screen and (min-width: 768px){
	.juehaob{
	  width: 85%;
	}
  }
  
  /* 手机端 */
  @media screen and (max-width: 767px) {
	.juehaob{
	  width: 93%;
	}	
  }

	/*绝好调按钮*/
.juehaob{
	margin: 15px;
	border-radius: 10px;
	padding: 8px 20px;
	border:1px solid #e1dcdc;
	transition-property: background-color;
	transition-duration: 1s;
  }
</style>
