<template>
  <div style="width: 100%;text-align: center;">
    <img clas="logo" alt="Gakumas logo" src="../assets/icons.png">
  </div>
  <div style="margin: 50px 0px;text-align: center;">
    本程序旨在简化【学园偶像大师】中的计算操作<br/>
		伤害计算：计算卡牌打出时的分值<br/>
		训练计算：计算最后一次训练后会影响的期末追加<br/>
		期末追加计算：计算最优追加选择方案<br/>
  </div>
  <div class="mbox">
    <div class="item" @click="itemClick(1)" style="background-color: #ddfffd;">
      SENSE流伤害计算
    </div>
    <div class="item" @click="itemClick(2)" style="background-color: #fefce8;">
      LOGIC-好印象流伤害计算
    </div>
    <div class="item" @click="itemClick(3)" style="background-color: #ffebebc8;">
      LOGIC-元气流伤害计算
    </div>
    <div class="item" @click="itemClick(4)" style="background-color: #e4ffd7c8;">
      最后一次训练计算
    </div>
    <div class="item" @click="itemClick(5)" style="background-color: #f7e7ffc8;">
      期末追加计算
    </div>
<!-- 			<div class="item" @click="itemClick(5)" style="background-color: #dbe5ffc8;">
      目标评分计算
    </div> -->
  </div>
</template>

<script>
	export default {
		methods: {
			itemClick(num){
        this.$parent.changePage(num);
			}
		}
	}
</script>

<style>
.mbox{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.item{
  color: #434040;
  width: 80%;
  height: 50px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding-left: 30px;
  border-radius: 20px;
}
</style>
